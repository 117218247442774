import React, { useContext } from 'react'
import { Route, Routes, useMatch } from 'react-router-dom'
import LiveUi from './LiveUi'
import { ConfigContext } from '../../store/ConfigContext'

const App = () => {
  const { localization } = useContext(ConfigContext)
  const match = useMatch('/:country/:language/*')
  return (
    <Routes>
      <Route
        path={`${
          localization.enabled && match ? match.pathnameBase : ''
        }/guided-setup/*`}
        element={<LiveUi />}
      />
    </Routes>
  )
}

export default App
