import React, { useContext, useEffect, useState } from 'react'
import { ConfigContext } from '../store/ConfigContext'
import { PrinterContext } from '../store/PrinterContext'
import { ActionContext } from '../store/ActionContext'
import useAnalytics from '../hooks/useAnalytics'
import { ANALYTICS, RESULT } from '../config/constants'
import { FullscreenProgress } from '../components/LiveUi/FullscreenProgress'
import { useNavigate } from 'react-router-dom'

const { SCREENS } = ANALYTICS

const PortalEntry = () => {
  const {
    sessionId,
    init: configInit,
    closeServiceInstance
  } = useContext(ConfigContext)
  const {
    productFamily,
    liveUiVersion,
    init: printerInit,
    isSupported,
    discoveryTree,
    oobeManifestTree,
    isLedm
  } = useContext(PrinterContext)
  const { findNextPage } = useContext(ActionContext)
  const [printerInitiated, setPrinterInitiated] = useState(false)
  const [nextPageLoaded, setNextPageLoaded] = useState(false)
  const { fireScreenDisplayed } = useAnalytics(SCREENS.START)
  const navigate = useNavigate()

  fireScreenDisplayed()

  useEffect(() => {
    if (!isSupported) {
      closeServiceInstance(RESULT.INELIGIBLE)
      return
    }
    configInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (sessionId !== '' && !printerInitiated && isSupported) {
      setPrinterInitiated(true)
      printerInit()
    }
  }, [sessionId, printerInit, printerInitiated, isSupported])

  useEffect(() => {
    if (
      !nextPageLoaded &&
      productFamily &&
      liveUiVersion &&
      ((!isLedm && discoveryTree) || (isLedm && oobeManifestTree))
    ) {
      setNextPageLoaded(true)
      findNextPage().then((route) => {
        route && navigate(`..${route}`)
      })
    } else if (liveUiVersion === -1) {
      closeServiceInstance(RESULT.INELIGIBLE)
    }
  }, [
    productFamily,
    liveUiVersion,
    findNextPage,
    nextPageLoaded,
    discoveryTree,
    oobeManifestTree,
    isLedm,
    closeServiceInstance,
    navigate
  ])

  return <FullscreenProgress />
}

export default PortalEntry
