import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import '@veneer/styles/typography/typography.css'
import ConfigProvider from '../../store/ConfigContext'
import PrinterProvider from '../../store/PrinterContext'
import ActionProvider from '../../store/ActionContext'
import ErrorProvider from '../../store/ErrorContext'
import FeatureFlagProvider from '../../store/FeatureFlagContext'
import App from './App'
import LoggingProvider from '../../store/LoggingContext'
import { Logger } from '../../utils/helpers'
import { useShellRootContext } from '../../contexts/ShellRoot'
import { Stack } from '@jarvis/web-stratus-client'

const AppWrapper = ({ stack = Stack.pie }) => {
  const { t, interfaces = {} } = useShellRootContext?.() || {}
  const {
    v1: {
      analytics,
      navigation,
      store,
      localization,
      serviceRouting,
      monitoring,
      featureFlags
    },
    v2: { authProvider }
  } = interfaces
  return (
    <Router>
      <LoggingProvider logger={Logger}>
        <ErrorProvider>
          <FeatureFlagProvider featureFlags={featureFlags}>
            <ConfigProvider
              authProvider={authProvider}
              navigation={navigation}
              analytics={analytics}
              store={store}
              stack={stack}
              localization={localization}
              serviceRouting={serviceRouting}
              monitoring={monitoring}
              t={t}
            >
              <PrinterProvider>
                <ActionProvider>
                  <App />
                </ActionProvider>
              </PrinterProvider>
            </ConfigProvider>
          </FeatureFlagProvider>
        </ErrorProvider>
      </LoggingProvider>
    </Router>
  )
}

export default AppWrapper
